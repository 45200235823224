<template>
  <base-section id="about-us" class="mt-5">
    <h1 class="text-h1">Tentang Kami</h1>
    <v-container>
      <v-row justify="center" class="mt-10">
        <v-col cols="12">
          <v-card class="visi-misi text-center" elevation="3">
            <h2 class="subtitle-1" style="color: #0c4f88; font-weight: bold">
              VISI
            </h2>
            <p style="font-size: 18px">
              Menjadi Mitra Penjaminan Terpercaya Bagi Pelaku Usaha Dalam
              Mendukung Perkembangan Bisnis Yang Sehat
            </p>
            <v-divider class="my-4"></v-divider>
            <h2 class="subtitle-1" style="color: #0c4f88; font-weight: bold">
              MISI
            </h2>
            <p style="font-size: 18px">
              Melakukan Kegiatan Penjaminan Secara Profesional Dengan Layanan
              Prima Bagi Para Pelaku Usaha
              <br />
              Menciptakan Produk Penjaminan Yang Inovatif dan Kreatif
            </p>
            <v-divider class="my-4"></v-divider>
            <h2 class="subtitle-1" style="color: #0c4f88; font-weight: bold">
              SEKILAS TENTANG KAMI
            </h2>
            <p class="text-left" style="font-size: 18px">
              PT. Orion Penjaminan Indonesia didirikan pada tanggal 12 April tahun 2023 dengan Akta Pendirian No. 5 yang dibuat dihadapan Arief Yulianto,S.H., M. Kn Notaris di Jakarta dan telah mendapat pengesahan dari Menteri Kehakiman RI tanggal 12 April 2023 Nomor: AHU- 0028748.AH.01.01.TAHUN 2023. Anggaran Dasar Perusahaan telah mengalami perubahan. Perubahan anggaran dasar terakhir diubah dengan Akta Perubahan Nomor: 09 tanggal 17 Mei 2023, yang dibuat oleh Arief Yulianto,S.H., M. Kn Notaris di Jakarta dan telah mendapat persetujuan dari Menteri Hukum dan HAM RI dengan Surat Keputusan Tanggal 17 Mei 2023 Nomor: AHU- AH.01.09-0118229.

              Modal disetor pada saat pendirian sebesar Rp 150.000.000.000,‐ Seratus lima puluh miliar rupiah, dengan Pemegang Saham Pengendali yaitu PT. Orion Sedaya Indonesia.
              Saat ini grup orion terbesar beroperasi dalam bidang usaha pembangunan, jasa, perdagangan, perindustrian dan investasi melalui anak perusahaannya PT City Retail Development Tbk (NIRO) yang pada September tahun 2023 mencatat total asset sebesar 13,6 Triliun.              
            </p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionAboutUs",
};
</script>

<style scoped>
.text-h1 {
  color: #0c4f88;
  text-align: center;
}

.visi-misi {
  padding: 20px;
  border-radius: 10px;
}
</style>
